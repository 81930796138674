// В этом файле собраны классы для переписывания стилей Angular Material по умолчанию

.mat-fab.mat-primary,
.mat-flat-button.mat-primary,
.mat-mini-fab.mat-primary,
.mat-raised-button.mat-primary {
    background-color: var(--primary);
}

.mat-fab.mat-accent,
.mat-flat-button.mat-accent,
.mat-mini-fab.mat-accent,
.mat-raised-button.mat-accent {
    background-color: var(--primary);
}

.mat-button.mat-accent .mat-button-focus-overlay,
.mat-icon-button.mat-accent .mat-button-focus-overlay,
.mat-stroked-button.mat-accent .mat-button-focus-overlay {
    background-color: rgba(255, 255, 255, 0.15);
}

.mat-simple-snackbar-action {
    color: var(--primary);
}

.mat-simple-snackbar {
    text-align: center;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: var(--primary);
}

.mat-ripple-element {
    background-color: rgba(211, 209, 209, 0.3) !important;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
    background-color: var(--primary);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: rgb(81, 164, 195);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: rgba(1, 188, 108, 0.8);
}

.mat-progress-spinner circle,
.mat-spinner circle {
    stroke: var(--primary);
}
.mat-progress-spinner {
    margin: 0 auto;
}
.mat-select-panel {
    max-width: 400px;
}

.mat-calendar-table {
    box-shadow: none;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color: var(--primary);
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: var(--primary);
}

.mat-button-toggle-checked .mat-button-toggle-focus-overlay {
    border-bottom: 0;
}

mat-paginator {
    bottom: 0;
    display: block;
    /*position: absolute;*/
    width: 100%;
}

.mat-progress-bar-buffer {
    background-color: #bfbaba;
}

.mat-progress-bar-fill::after {
    background-color: var(--primary);
}

.mat-tab-header {
    box-shadow: 4px 4px 10px rgba(186, 186, 193, 0.32);
    border-radius: 8px;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: var(--primary);
}

.mat-sort-header-button {
    text-align: left;
}

td.mat-cell,
td.mat-footer-cell,
th.mat-header-cell {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.mat-sort-header-container {
    min-width: 40px;
    justify-content: center;
}

.mat-menu-panel {
    min-width: 0 !important;
    max-width: 100% !important;
}

.mat-tab-label .mat-tab-label-content {
    font-weight: bold;
}

.mat-card {
    box-shadow: 4px 4px 10px rgba(186, 186, 193, 0.32);
    border-radius: 8px !important;
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
    color: #4e6071;
}

.mat-form-field.mat-focused .mat-form-field-label {
    color: #4e6071;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
    background: #2f4050;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: #102d48;
}

.mat-button-toggle {
    border-radius: 5px;
    margin-right: 5px;
}

table tr th.mat-header-cell {
    color: #000 !important;
    font-weight: bold !important;
    font-size: 12px !important;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    font-size: 12px !important;
}

.mat-button,
.mat-raised-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button,
.mat-fab,
.mat-mini-fab,
.mat-tab-label,
.mat-tab-link,
.mat-cell,
.mat-footer-cell {
    font-size: 13px;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: grey;
}

.mat-button.safe-text {
    overflow: hidden;
    word-break: break-all;
    white-space: break-spaces;
}

.mat-mini-fab .mat-button-wrapper{
    padding: 0 !important;
}

// кастомный(костыльный) класс mat-selection-list-selected-custom для определения выбранного элемента для mat-selection-list
.mat-list-item.mat-selection-list-selected-custom {
    background-color: var(--primary);
    color: #fff;
    font-weight: bold;
}
