@import url('/src/assets/css/media');
// В этом файле собраны классы по позиционированию элементов, а также флекс и грид

// *** POSITION ***

.absolute {
    position: absolute;
}

.relative {
    position: relative;
}

.fixed {
    position: fixed;
}

.sticky {
    position: sticky;
}

.hide-for-mobile {
    @media @mobile {
        display: none;
    }
}

// *** TEXT ***

.text-center {
    text-align: center;
}

.safe-text {
    overflow: hidden;
    word-break: break-all;
    white-space: break-spaces;
}

.safe-text-word {
    overflow: hidden;
    word-break: break-word;
    white-space: break-spaces;
}

// *** FLEX ***

.flex {
    display: flex;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-center-vertical {
    display: flex;
    align-items: center;
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-center-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.flex-between {
    display: flex;
    justify-content: space-between;
}

.flex-between-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex-evenly {
    display: flex;
    justify-content: space-evenly;
}

.flex-start {
    display: flex;
    justify-content: flex-start;
}

.flex-end {
    display: flex;
    justify-content: flex-end;
}

.flex-fill {
    flex: 1 0 auto;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-wrap-mobile {
    @media @mobile {
        flex-wrap: wrap;
    }
}

//TODO: delete grid section if no usage

// *** GRID ***

.grid {
    display: grid;
}
