// В этом файле собраны общие классы для использования по всему приложению

.atlwdg-trigger.atlwdg-RIGHT {
    z-index: 9999999;
}

app-content > div:nth-child(1),
table {
    border-radius: 4px;
    overflow: hidden;
    width: 100%;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
}

.report table {
    box-shadow: none;
}

mat-card.content,
mat-tab.content,
mat-tab-body {
    min-height: calc(100vh - 650px);
}

div.main-content {
    min-height: calc(100vh - 70px);
}

dual-list button.btn-block {
    padding: 10px 0 !important;
    color: #ffffff !important;
    background-color: #4e6071 !important;
    border: none !important;
    border-radius: 4px !important;
    cursor: pointer !important;
    font-family: Roboto, 'Helvetica Neue', sans-serif !important;
    font-size: 14px !important;
    font-weight: 500 !important;
}

dual-list .dual-list .button-bar button {
    padding: 10px 0 !important;
    color: #ffffff !important;
    background-color: #4e6071 !important;
    border: none !important;
    border-radius: 4px !important;
    cursor: pointer !important;
    font-family: Roboto, 'Helvetica Neue', sans-serif !important;
    font-size: 14px !important;
    font-weight: 500 !important;
}

dual-list .dual-list .button-bar {
    display: flex;
    text-align: center !important;
}

dual-list .dual-list .button-bar button:first-child {
    margin-right: 20px !important;
}

.load_select_placeholder .mat-select-value {
    padding-left: 30px !important;
}

.record-picker li {
    min-height: 25px;
    line-height: 25px;
    font-size: 1em;
}

.bs-icon-map {
    margin: 0 0 -58px 0;
    position: relative !important;
}

mat-card.chart mat-progress-spinner svg {
    width: 50px !important;
    height: 50px !important;
}

#__replain_widget {
    bottom: 0 !important;
    right: 0 !important;
}

.object-element > .mat-list-item-content {
    padding: 0 5px !important;
}

.tree-element-radio-button > label > div.mat-radio-container,
.tree-element-radio-button > label > div.mat-radio-container > div.mat-radio-outer-circle,
.tree-element-radio-button > label > div.mat-radio-container > div.mat-radio-inner-circle {
    width: 15px;
    height: 15px;
}

.tree-device-element-radio-button > label > div.mat-radio-container {
    display: none;
}

.tree-element-radio-button .mat-radio-label {
    white-space: normal;
}

.overflow {
    overflow: hidden !important;
}

.header-mat-card,
.page-header {
    background: none;
    box-shadow: none !important;
    color: #181f23;
    margin: 0;
    padding: 0 0 0 25px !important;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
}

.header-mat-card .mat-card-title,
.header-mat-card strong,
.page-header strong {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 16px;
}

.timepicker .timepicker__header {
    background-color: var(--primary) !important;
}

.timepicker .clock-face__clock-hand {
    background-color: var(--primary) !important;
}

.timepicker .clock-face__clock-hand::after {
    background-color: var(--primary) !important;
}

.timepicker .clock-face__clock-hand::before {
    background-color: var(--primary) !important;
}

.timepicker .clock-face__number > span.active {
    background-color: var(--primary) !important;
}

.timepicker .clock-face__clock-hand_minute::before {
    border: 4px solid var(--primary) !important;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
}

mat-card.content {
    box-shadow: unset !important;
}

.loading-shade {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: var(--black-shadow-12);
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.virtual-scroll {
    height: 300px;
}

.leaflet-control-attribution {
    a, span {
        display: none !important;
    }
}

.pointer {
    cursor: pointer;
}

.overflow-auto {
    overflow: auto;
}
