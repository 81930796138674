// В этом файле собраны функции генераторы классов для размеров

// *** WIDTH ***

// width (w-10 = width: 10%)
each(range(0, 100, 5), {
    .w-@{value} {
        width: unit(@value, %);
    }
});

// min-width (min-w-10 = min-width: 10%)
each(range(0, 100, 5), {
    .min-w-@{value} {
        min-width: unit(@value, %);
    }
});

// max-width (max-w-10 = max-width: 10%)
each(range(0, 100, 5), {
    .max-w-@{value} {
        max-width: unit(@value, %);
    }
});

// *** HEIGHT ***

// height (h-10 = height: 10%)
each(range(0, 100, 5), {
    .h-@{value} {
        height: unit(@value, %);
    }
});

// min-height (min-h-10 = min-height: 10%)
each(range(0, 100, 5), {
    .min-h-@{value} {
        min-height: unit(@value, %);
    }
});

// max-height (max-h-10 = max-height: 10%)
each(range(0, 100, 5), {
    .max-h-@{value} {
        max-height: unit(@value, %);
    }
});
