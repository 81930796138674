// В этом файле собраны функции генераторы классов для оффсетов

//@base-offset: var(--base-offset); // TODO: find way to use --base-offset
@base-offset: 4px;
@max-class-count: 4; // верхний лимит для сгенеированных классов например mt-4, mt-5 уже не будет

// *** MARGINS ***

.m-center {
    margin: 0 auto;
}

// margin (m-0 = margin: 0)
each(range(0, @max-class-count, 1), {
    .m-@{value} {
        margin: @base-offset * @value;
    }
});

// margin-top (mt-0 = margin-top: 0)
each(range(0, 4, 1), {
    .mt-@{value} {
        margin-top: @base-offset * @value;
    }
});

// margin-bottom (mb-0 = margin-bottom: 0)
each(range(0, @max-class-count, 1), {
    .mb-@{value} {
        margin-bottom: @base-offset * @value;
    }
});

// margin-right (mr-0 = margin-right: 0)
each(range(0, @max-class-count, 1), {
    .mr-@{value} {
        margin-right: @base-offset * @value;
    }
});

// margin-left (ml-0 = margin-left: 0)
each(range(0, @max-class-count, 1), {
    .ml-@{value} {
        margin-left: @base-offset * @value;
    }
});

// margin-vertical (mv-1 = margin: @base-offset auto)
each(range(0, @max-class-count, 1), {
    .mv-@{value} {
        margin: @base-offset * @value auto;
    }
});

// margin-horizontal (mh-1 = margin: auto @base-offset)
each(range(0, @max-class-count, 1), {
    .mh-@{value} {
        margin: auto @base-offset * @value;
    }
});

// *** PADDINGS ***

// padding (p-0 = padding: 0)
each(range(0, @max-class-count, 1), {
    .p-@{value} {
        padding: @base-offset * @value;
    }
});

// padding-top (pt-0 = padding-top: 0)
each(range(0, @max-class-count, 1), {
    .pt-@{value} {
        padding-top: @base-offset * @value;
    }
});

// padding-bottom (pb-0 = padding-bottom: 0)
each(range(0, @max-class-count, 1), {
    .pb-@{value} {
        padding-bottom: @base-offset * @value;
    }
});

// padding-right (pr-0 = padding-right: 0)
each(range(0, @max-class-count, 1), {
    .pr-@{value} {
        padding-right: @base-offset * @value;
    }
});

// padding-left (pl-0 = padding-left: 0)
each(range(0, @max-class-count, 1), {
    .pl-@{value} {
        padding-left: @base-offset * @value;
    }
});

// padding-vertical (pv-1 = padding: @base-offset 0)
each(range(0, @max-class-count, 1), {
    .pv-@{value} {
        padding: @base-offset * @value 0;
    }
});

// padding-horizontal (ph-1 = padding: 0 @base-offset)
each(range(0, @max-class-count, 1), {
    .ph-@{value} {
        padding: 0 @base-offset * @value;
    }
});

// *** GAPS ***

// gap (g-0 = gap: 0)
each(range(0, @max-class-count, 1), {
    .g-@{value} {
        gap: @base-offset * @value;
    }
});
