// В этом файле собраны первочальные настройки

* {
    font-family: 'Open Sans', sans-serif;
}

html,
body {
    height: 100%;
    font-size: 0.95rem;
}

body {
    margin: 0;
    font-family: 'Open Sans', sans-serif;
    //background: rgb(219, 232, 238); //исходное значение
    background: #f1f1f1; //значение из nekta.css
}

@media (max-width: 1420px) {
    * {
        font-size: 0.85rem;
    }
}
