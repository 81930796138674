// В этом файле собраны все базовые переменные

:root {
    // *** OFFSETS ***
    --base-offset: 4px;

    // *** COLORS ***
    --primary: #01BC6C;
    --orange: #e65100;
    --black-shadow-12: rgba(0, 0, 0, 0.12);
    --black-shadow-84: rgba(0, 0, 0, 0.84);
    --blue-gray: #607d8b;
    --red-dark: #980000;
    --green-dark: #008000;
    --cell-success: #9ccc65;
    --cell-error: #c62828;
    --gray: #616161;
    --gray-standard: #808080FF;
    --gray-top-middle: #d3d3d338;
    --brown: #5d4037;
    --border-light: #e0e0e0;
    --notifications-error: #CF5555;
    --notifications-warning: #EBB769;
    --notifications-success: #4CAF50;
}
