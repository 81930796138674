// В этом файле собраны классы для переписывания стилей Angular CDK по умолчанию

.cdk-overlay-container {
    position: fixed;
    z-index: 100;
}

.cdk-global-overlay-wrapper {
    justify-content: center;
    align-items: flex-end;
}

cdk-virtual-scroll-viewport:hover {
    padding-right: 10px;
}

cdk-virtual-scroll-viewport::-webkit-scrollbar {
    width: 10px; /* ширина scrollbar */
}

cdk-virtual-scroll-viewport::-webkit-scrollbar-track {
    background: rgba(206, 206, 206, 0.2);
    border-radius: 20px;
}

cdk-virtual-scroll-viewport::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.11);
    border-radius: 20px;
}

//cdk-virtual-scroll-viewport {
//    scrollbar-width: none; /* Hide scrollbar on Firefox */
//    -ms-overflow-style: none; /* Hide scrollbar on IE and Edge */
//    overflow: -webkit-hidden-scrollbar; /* Hide scrollbar on Chrome, Safari,
//    and newer versions of Edge */
//}
