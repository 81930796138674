@import '../node_modules/x-data-spreadsheet/dist/xspreadsheet.css';

@import 'assets/css/_reset.less';
@import 'assets/css/_variables.less';
@import 'assets/css/_fonts';
@import 'assets/css/_offsets.less';
@import 'assets/css/_dimensions.less';
@import 'assets/css/_positions.less';
@import 'assets/css/_common.less';
@import 'assets/css/_material_override.less';
@import 'assets/css/_cdk_override.less';
@import 'assets/css/_colors.less';
